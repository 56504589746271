export const RAFFLES_REG_TERM = [
  {
    from: '2024-07-15 00:00',
    to: '2024-07-21 23:59',
    raffle: { day: 23, month: 'июля' },
  },
  {
    from: '2024-07-22 00:00',
    to: '2024-07-28 23:59',
    raffle: { day: 30, month: 'июля' },
  },
  {
    from: '2024-07-29 00:00',
    to: '2024-08-04 23:59',
    raffle: { day: 6, month: 'августа' },
  },
  {
    from: '2024-08-05 00:00',
    to: '2024-08-11 23:59',
    raffle: { day: 13, month: 'августа' },
  },
  {
    from: '2024-08-12 00:00',
    to: '2024-08-18 23:59',
    raffle: { day: 20, month: 'августа' },
  },
  {
    from: '2024-08-19 00:00',
    to: '2024-08-25 23:59',
    raffle: { day: 27, month: 'августа' },
  },
  {
    from: '2024-08-26 00:00',
    to: '2024-09-01 23:59',
    raffle: { day: 3, month: 'сентября' },
  },
  {
    from: '2024-09-02 00:00',
    to: '2024-09-08 23:59',
    raffle: { day: 10, month: 'сентября' },
  },
]
