export const PreloaderThematicTreasureIslandGame = ({ margin }) => {
  return (
    <lottie-player
      src="/lottie/ship-control-wheel.json"
      background="transparent"
      speed="1"
      style={{ width: '400px', height: '400px' }}
      loop
      autoplay
    ></lottie-player>
  )
}

PreloaderThematicTreasureIslandGame.defaultProps = {}
