import { TextTitleHomeNewYearGame } from './TextTitleHomeNewYearGame'
import { TextTitleHomeTreasureIslandGame } from './TextTitleHomeTreasureIslandGame'

const TextTitleHomeByGameId = {
  1: TextTitleHomeNewYearGame,
  2: TextTitleHomeTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleHome = TextTitleHomeByGameId[gameId]
