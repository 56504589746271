import { TicketListContentNewYearGame } from './TicketListContentNewYearGame'
import { TicketListContentTreasureIslandGame } from './TicketListContentTreasureIslandGame'

const TicketListContentByGameId = {
  1: TicketListContentNewYearGame,
  2: TicketListContentTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TicketListContent = TicketListContentByGameId[gameId]
