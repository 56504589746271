import { TextTitleCalendarsNewYearGame } from './TextTitleCalendarsNewYearGame'
import { TextTitleCalendarsTreasureIslandGame } from './TextTitleCalendarsTreasureIslandGame'

const TextTitleCalendarsByGameId = {
  1: TextTitleCalendarsNewYearGame,
  2: TextTitleCalendarsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleCalendars = TextTitleCalendarsByGameId[gameId]
