import { fortuneValueTextPropsNewYearGame } from './fortuneValueTextPropsNewYearGame'
import { fortuneValueTextPropsTreasureIslandGame } from './fortuneValueTextPropsTreasureIslandGame'

const fortuneValueTextPropsByGameId = {
  1: fortuneValueTextPropsNewYearGame,
  2: fortuneValueTextPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const fortuneValueTextProps = fortuneValueTextPropsByGameId[gameId]
