import { couponValueTextPropsNewYearGame } from './couponValueTextPropsNewYearGame'
import { couponValueTextPropsTreasureIslandGame } from './couponValueTextPropsTreasureIslandGame'

const couponValueTextPropsByGameId = {
  1: couponValueTextPropsNewYearGame,
  2: couponValueTextPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const couponValueTextProps = couponValueTextPropsByGameId[gameId]
