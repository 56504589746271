import { BoardSceneNewYearGame } from './BoardSceneNewYearGame'
import { BoardSceneTreasureIslandGame } from './BoardSceneTreasureIslandGame'

const BoardSceneByGameId = {
  1: BoardSceneNewYearGame,
  2: BoardSceneTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const BoardScene = BoardSceneByGameId[gameId]
