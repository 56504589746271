import { Box, Button, Text } from 'ui/atoms'
import { PopupTicketGiftBase } from 'ui/molecules'
import { buttonProps } from './buttonProps'
import { headingTextProps } from './headingTextProps'
import { congratulationsTextProps } from './congratulationsTextProps'
import { ticketValueTextProps } from './ticketValueTextProps'
import { raffleDateTextProps } from './raffleDateTextProps'
import { config } from 'config'

export const PopupTicketGift = ({
  raffleDate,
  ticketValue,
  isOpened,
  onClose,
}) => (
  <PopupTicketGiftBase isOpened={isOpened} onClose={onClose}>
    <Box {...headingTextProps}>
      {config.texts.popups.ticketGift.heading.map((textLine, index) => {
        return <div key={index}>{textLine}</div>
      })}
    </Box>
    <Box {...congratulationsTextProps}>
      {config.texts.popups.ticketGift.congratulations.map((textLine, index) => {
        return <div key={index}>{textLine}</div>
      })}
    </Box>
    <Text {...ticketValueTextProps}>№ {ticketValue}</Text>
    <Text {...raffleDateTextProps}>Розыгрыш: {raffleDate} г.</Text>
    <Button {...buttonProps} onClick={onClose}>
      Закрыть
    </Button>
  </PopupTicketGiftBase>
)
