import { KeyWrapperNewYearGame } from './KeyWrapperNewYearGame'
import { KeyWrapperTreasureIslandGame } from './KeyWrapperTreasureIslandGame'

const KeyWrapperByGameId = {
  1: KeyWrapperNewYearGame,
  2: KeyWrapperTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeyWrapper = KeyWrapperByGameId[gameId]
