import { ticketValuePropsNewYearGame } from './ticketValuePropsNewYearGame'
import { ticketValuePropsTreasureIslandGame } from './ticketValuePropsTreasureIslandGame'

const ticketValuePropsByGameId = {
  1: ticketValuePropsNewYearGame,
  2: ticketValuePropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketValueProps = ticketValuePropsByGameId[gameId]
