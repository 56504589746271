import { PopupPanelNewYearGame } from './PopupPanelNewYearGame'
import { PopupPanelTreasureIslandGame } from './PopupPanelTreasureIslandGame'

const PopupPanelByGameId = {
  1: PopupPanelNewYearGame,
  2: PopupPanelTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupPanel = PopupPanelByGameId[gameId]
