import { useDispatch } from 'react-redux'

import { Box, Button, Text } from 'ui/atoms'
import { setIsFirstVisit } from 'store/slices'
import { keyImgProps } from './keyImgProps'
import { KeyImg } from './KeyImg'
import { KeyDescriptionWrapper } from './KeyDescriptionWrapper'
import { config } from 'config'
import { textDescriptionProps } from './textDescriptionProps'

export const KeyDescription = () => {
  const dispatch = useDispatch()

  const handleContinueBtnClick = () => {
    dispatch(setIsFirstVisit(false))
  }

  return (
    <KeyDescriptionWrapper>
      <KeyImg {...keyImgProps} />
      <Text
        fontWeight="700"
        fontSize="28px"
        lineHeight="31px"
        color="#ffffff"
        textTransform="uppercase"
        mb="20px"
        textAlign="center"
      >
        {config.texts.keyDescription.heading}
      </Text>
      <Box {...textDescriptionProps}>
        <Text
          fontWeight="500"
          fontSize="16px"
          lineHeight="19px"
          letterSpacing="0.5px"
          color="#ffffff"
          mb="40px"
          textAlign="center"
        >
          {config.texts.keyDescription.description}
        </Text>
      </Box>

      <Button variant="secondary" w="295px" onClick={handleContinueBtnClick}>
        Продолжить
      </Button>
    </KeyDescriptionWrapper>
  )
}
