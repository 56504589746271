import { ticketsInfoWrapperPropsDesktopNewYearGame } from './ticketsInfoWrapperPropsDesktopNewYearGame'
import { ticketsInfoWrapperPropsDesktopTreasureIslandGame } from './ticketsInfoWrapperPropsDesktopTreasureIslandGame'

const ticketsInfoWrapperPropsDesktopByGameId = {
  1: ticketsInfoWrapperPropsDesktopNewYearGame,
  2: ticketsInfoWrapperPropsDesktopTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketsInfoWrapperPropsDesktop =
  ticketsInfoWrapperPropsDesktopByGameId[gameId]
