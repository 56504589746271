import { newYearGameConfig } from './newYearGameConfig'
import { treasureIslandGameConfig } from './treasureIslandGameConfig'

export const configs = {
  1: newYearGameConfig,
  2: treasureIslandGameConfig,
}

const gameId = process.env.REACT_APP_GAME_ID
export const config = configs[gameId]
