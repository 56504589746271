import { GlobalStyle } from 'ui/settings/global'
import { Text } from '../../ui/atoms'
import { LayoutMobile } from '../../ui/layout'
import { config } from 'config'
import { headingTextProps } from './headingTextProps'
import { contentTextProps } from './contentTextProps'

export const Rules = () => (
  <GlobalStyle>
    <LayoutMobile
      {...config.backgrounds.RULES_BACKGROUND}
      h="auto"
      isRulesPage={true}
    >
      <LayoutMobile.Content>
        <Text
          {...headingTextProps}
          as="h1"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          ПРАВИЛА СТИМУЛИРУЮЩЕЙ АКЦИИ «ОСТРОВ СОКРОВИЩ. ПЕРЕЗАГРУЗКА»
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          Настоящая стимулирующая акция под названием «Остров сокровищ.
          Перезагрузка» (далее по тексту - Акция) проводится согласно изложенным
          ниже условиям (далее по тексту – Правила) в рамках рекламной кампании.
          Данная Акция не является лотереей либо иной игрой, основанной на
          риске, не требует внесения платы за участие. Процедура проведения
          Акции не связана с внесением Участниками платы за принятие в ней
          участия, призовой фонд Акции сформирован за счет средств Организатора
          акции.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          1.ОБЩИЕ ПОЛОЖЕНИЯ ПРОВЕДЕНИЯ СТИМУЛИРУЮЩЕЙ АКЦИИ «ОСТРОВ СОКРОВИЩ.
          ПЕРЕЗАГРУЗКА»
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.1. Наименование Акции: «Остров сокровищ. Перезагрузка».
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.2. Организатор Акции: Общество с ограниченной ответственностью
          «Евроритейл» (далее по тексту настоящих Правил - «Организатор»).
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.3. Территория проведения Акции - Россия, Калининградская область
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.4. Наименование Организатора. <br />
          Организатором Акции, то есть юридическим лицом, созданным в
          соответствии с законодательством Российской Федерации, организующим
          проведение Акции непосредственно и/или через Оператора, является
          Общество с ограниченной ответственностью «Евроритейл»: <br />
          - местонахождение: 236001, Калининградская обл., г. Калининград, ул.
          Люблинское шоссе д. 6. <br />
          - ОГРН 1143926011316, ИНН 3906321175. <br />
          Источник информации об организаторе Акции, о правилах ее проведения,
          количество призов по результатам Акции, сроках, месте и порядке их
          получения – сайт программы лояльности Организатора в сети интернет
          friendsclub.ru и мобильное приложение Клуба Друзей.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.5. Генеральными партнерами Акции являются: ООО «Тетрис-Калининград»
          и ООО «Робот-Икс».
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          1.6. Участники Акции: являются покупатели магазинов «SPAR» «EUROSPAR»,
          «INTERSPAR», «SPAR Express», «Семья» и «Победа», достигшие возраста 18
          лет, в городах и населенных пунктах Калининградской области, кроме
          сотрудников ООО «Евроритейл», ООО «Тетрис-Калининград» и ООО «Робот
          Икс» (в т.ч. бывших сотрудников указанных компаний, уволившихся
          позднее 01.01.2024 года), а также близких родственников данных
          сотрудников и сотрудников внешних аутсорсинговых компаний, которые
          обслуживают данные организации.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          2. СРОКИ ПРОВЕДЕНИЯ АКЦИИ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          2.1. Общий срок проведения Акции: <br />
          Акция проводится в период с 15 июля 2024 г. по 08 сентября 2024 года
          (включительно), не включая срок выдачи Призов Победителям Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          2.2. Выдача лопат для открытия острова и получения призов Акции, в том
          числе электронных золотых билетов для участия в розыгрыше Призов
          первого уровня, происходит ежедневно с 10 часов 00 минут 00 секунд 15
          июля 2024 года до 23 часов 59 минут 59 секунд 08 сентября 2024 года
          (включительно) по местному времени в разделе Акции на сайте программы
          лояльности Организатора friendsclub.ru и в мобильном приложении Клуба
          Друзей за покупки в магазинах «SPAR» «EUROSPAR», «INTERSPAR», «SPAR
          Express», «Семья» и «Победа» Калининградской области.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          2.3. Выдача Призов Акции происходит с 15 июля 2024 года по 10 сентября
          2024 года по рабочим и выходным дням в период времени с 13 часов 00
          минут 00 секунд до 18 часов 00 минут 00 секунд в «Центрах выдачи
          призов» по адресам: г. Калининград, ул. Панина, 2а; Калининградская
          область, пос. Васильково, ул. Шатурская, 2; Калининградская область,
          пос. Орловка, ул. Приморское полукольцо, 1; г. Калининград, Люблинское
          шоссе, 6 (далее – «Период Выдачи Призов»), а также в разделе Акции на
          сайте программы лояльности Организатора friendsclub.ru и в мобильном
          приложении Клуба Друзей . Адреса «Центров выдачи призов» в ходе
          проведения Акции могут меняться. Конкретное время выдачи приза заранее
          согласовывается с каждым Победителем Акции.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          3. ПРИЗОВОЙ ФОНД АКЦИИ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          3.1. Призовой фонд Акции формируется за счет средств Организатора
          Акции и Партнеров Акции и состоит из: <br />
          Призов Акции (приз первого уровня): <br />
          1. <b>Смартфоны</b> Apple iPhone 15 128Gb (стоимость 89 490 руб.) - 24
          штук. <br />
          Всего призов первого уровня – 24 штук (далее – «Приз Акции», а все
          вместе – «Призы Акции»). <br />
          <br />
          Примерный перечень дополнительных поощрений, которые могут выпасть
          случайным образом за любым из открытых окошек календаря: <br />
          <br />
          - Предсказание от SPAR. <br />
          - Купон на кофе навынос в подарок при покупке в магазинах SPAR и
          «Семья». <br />
          - Купон с честной скидкой 10% на покупку одного товара в магазинах
          SPAR и «Семья». <br />
          - Купон с честной скидкой 15% на покупку одного товара в магазинах
          SPAR и «Семья». <br />
          - Купон с честной скидкой 20% на покупку одного товара в магазинах
          SPAR и «Семья». <br />
          - Купон с честной скидкой 25% на покупку одного товара в магазинах
          SPAR и «Семья». <br />
          - Купон с честной скидкой 30% на покупку одного товара в магазинах
          SPAR и «Семья». <br />
          - Купон с честной скидкой 99% на покупку одного товара в магазинах
          SPAR и «Семья». <br />
          - Купон на 10 бонусов при любой покупке в магазинах SPAR, «Семья» и
          «Победа». <br />
          - Купон на 20 бонусов при покупке от 100 руб. в магазинах SPAR,
          «Семья» и «Победа». <br />
          - Купон на 30 бонусов при покупке от 300 руб. в магазинах SPAR,
          «Семья» и «Победа». <br />
          - Купон на 50 бонусов при покупке от 500 руб. в магазинах SPAR,
          «Семья» и «Победа». <br />
          - Купон на 100 бонусов при покупке от 800 руб. в магазинах SPAR,
          «Семья» и «Победа». <br />
          - Купон на 300 бонусов при покупке от 1 000 руб. в магазинах SPAR,
          «Семья» и «Победа». <br />
          - Купон на 500 бонусов при покупке от 2 000 руб. в магазинах SPAR,
          «Семья» и «Победа». <br />
          - Купон на 1000 бонусов при покупке от 3 000 руб. в магазинах SPAR,
          «Семья» и «Победа». <br />
          - Арбуз весовой в подарок при любой покупке. <br />
          - Квас в подарок при любой покупке. <br />
          - Манго в подарок при любой покупке. <br />
          - Мороженое Экзо в подарок при любой покупке. <br />
          - Киви фасованный в подарок при любой покупке. <br />
          - Сок 2 л в подарок при любой покупке. <br />
          - Скидка 30% на категорию мороженое больших объемов. <br />
          - Скидка 50% на категорию мороженое больших объемов. <br />
          - Крем для рук в подарок при любой покупке. <br />
          - Скидка 30% на категорию «Консервация». <br />
          - Скидка 30% на категорию «напитки». <br />
          - Скидка 30% на категорию подарочных наборов (Демшина). <br />
          - Скидка 30% на категорию «Бытовая химия». <br />
          - Скидка 30% на категорию «Текстиль». <br />
          - Скидка 30% на категорию «Посуда для сервировки». <br />и другие.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          3.2. Указанная стоимость Призов первого уровня является
          ориентировочной, окончательная стоимость будет указана в документах,
          подписываемых с Победителями в период выдачи Призов.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          3.3. Организатор оставляет за собой право до даты окончания Акции
          вносить изменения в указанный в настоящем пункте перечень Призов, в
          т.ч. производить их замену на Призы иной стоимости и комплектации.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          3.4. Внешний вид, комплектация оригинального Приза первого уровня
          может отличаться от его изображения в рекламных материалах.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          3.5. Об обязанности уплаты налога на доход физического лица читайте в
          п.5.2. настоящих Правил.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          4. УСЛОВИЯ АКЦИИ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.1. Участники Акции имеют, в частности, следующие права: <br />
          право на получение информации об Акции в соответствии с настоящими
          Правилами; <br />
          право на получение Призов Акции в случае, если Участник будет признан
          выигравшим, в соответствии с настоящими Правилами; <br />
          иные права, предусмотренные настоящими Правилами и действующим
          законодательством Российской Федерации.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.2. Участники Акции несут, в частности, следующие обязанности: <br />
          соблюдать Правила Акции во время ее проведения; <br />
          предоставлять Организатору достоверную информацию о себе в
          соответствии с Правилами Акции; <br />
          иные обязанности, предусмотренные настоящими Правилами и действующим
          законодательством Российской Федерации.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.3. Чтобы принять участие в Акции и получить, нужно: <br />
          -совершить покупку в любом магазине «SPAR», «EUROSPAR», «INTERSPAR»,
          «SPAR Express», «Семья» и «Победа» с предъявлением Карты Друга
          программы лояльности Организатора с 10:00 15 июля 2024 года до 23
          часов 59 минут 00 секунд 08 сентября 2024 года (по местному времени) с
          общей суммой чека не менее 800 рублей или совершить покупку
          специального товара-спонсора Акции. <br />
          -Воспользоваться полученным электронной лопатой для открытия острова и
          получения приза в разделе Акции на сайте программы лояльности
          Организатора friendsclub.ru или в мобильном приложении Клуба Друзей.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.4. Электронные лопаты для открытия острова и получения приза
          выдаются: <br />
          1. За суммарную покупку свыше 800 рублей в чеке Участник Акции
          получает 1 электронную лопату (например, 850 рублей = 1 фишка, 1600
          рублей = 1 фишка) на сайте программы лояльности организатора
          friendsclub.ru или в мобильном приложении Клуба Друзей. <br />
          2. За покупку и наличие в чеке специального товара-спонсора Участник
          акции получает 1 электронную лопату. <br />
          3. При любом заказе в интернет-магазине SPAR ONLINE на сайте
          spar-online.ru или через мобильное приложение «Клуба Друзей» Участник
          акции получает 3 электронных лопаты в разделе Акции на сайте программы
          лояльности Организатора friendsclub.ru или в мобильном приложении
          Клуба Друзей. <br />
          4. При любой покупке от 800 рублей в магазинах SPAR, «Семья» и
          «Победа» Калининградской области с помощью платёжного сервиса KD pay
          Участник Акции получает 3 дополнительных электронных лопаты в разделе
          Акции на сайте программы лояльности Организатора friendsclub.ru или в
          мобильном приложении Клуба Друзей. Дополнительные ключики начисляются
          по итогам прошедшей недели не позднее следующего за этой неделей
          вторника. Каждый Участник Акции может получить не более 3х
          дополнительных лопат в неделю за все покупки с KD pay в рамках этой
          недели. <br />
          <br />
          Каждый Участник Акции может совершить не более 7 покупок в день с
          применением одной Карты Друга. <br /> <br />
          Электронная лопата выдается в расчете от итоговой суммы Чека, после
          учета всех скидок или за наличие в чеке специального товара-спонсора.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.5. Для участия в розыгрыше приза первого уровня iPhone 15
          (еженедельного розыгрыша) необходимо получить «Золотой билет» после
          открытия острова в разделе Акции на сайте программы лояльности
          Организатора friendsclub.ru или в мобильном приложении Клуба Друзей.{' '}
          <br />
          При получении «Золотого билета» Участнику Акции автоматически
          присваивается уникальный код для еженедельного розыгрыша.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.6. Один Участник Акции (покупатель, зарегистрированный в Программе
          лояльности Организатора «Клуб Друзей» и указавший номер своего
          мобильного телефона) может получить не более 30 золотых билетов с
          уникальным кодом в неделю для участия в еженедельном розыгрыше. <br />
          <br />
          При смене номера телефона, с помощью которого Участник Акции был
          зарегистрирован в программе лояльности «Клуб Друзей», все выпущенные
          ранее для этого номера золотые билеты и другие призы сгорают и не
          подлежат восстановлению. <br />
          <br />
          Подозрительные активности будут удалены из общей базы участников, без
          предупреждения самих участников.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          4.7. За каждый товар-спонсора покупатель получает электронную лопату
          для открытия окошка календаря и получения приза. Если в чеке 30 разных
          товаров-спонсоров, то покупатель получает дополнительно 30 электронных
          лопат. Однако количество одинаковых товаров-спонсоров, за которые
          покупатель может получить дополнительные ключики, не может превышать 5
          штук в одном чеке. Например, при наличии 5 одинаковых
          товаров-спонсоров в одной покупке — покупатель получит 5 лопат, а за
          покупку 10 таких же товаров он также получит 5 лопат.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          5. ПОРЯДОК ОПРЕДЕЛЕНИЯ ПОБЕДИТЕЛЯ АКЦИИ И ВЫДАЧИ ПРИЗОВ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.1. Для определения Победителей Акции Организатор создает экспертную
          комиссию, состоящую из трех человек. Члены комиссии – это граждане РФ,
          совершеннолетние, дееспособные и правоспособные. <br />- Один
          представитель от Организатора Акции для контроля за проведением
          процедуры определения победителей Акции и соответствия ее Правилам.{' '}
          <br />- Один председатель комиссии: представитель от Организатора
          Акции для предоставления реестра всех зарегистрированных чеков в Акции
          на мероприятии, по определению победителей Акции. Контроль за
          проведением процедуры определения победителей Акции и соответствия ее
          Правилам участия.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.2. Период определения Победителей первого уровня Акции происходит
          этапами: <br />
          Розыгрыш 24 ценных призов Первого уровня проходит согласно графику:{' '}
          <br />
          <br />
          1. период выдачи золотых билетов с 15 июля по 21 июля 2024 года до 23
          часов 59 минут - дата определения победителей 19:00, <b>
            23 июля
          </b>{' '}
          2024 года; <br />
          2. период выдачи золотых билетов с 22 июля по 28 июля 2024 года до 23
          часов 59 минут - дата определения победителей 19:00, <b>
            30 июля
          </b>{' '}
          2024 года; <br />
          3. период выдачи золотых билетов с 29 июля по 04 августа 2024 года до
          23 часов 59 минут - дата определения победителей 19:00,{' '}
          <b>6 августа</b> 2024 года; <br />
          4. период выдачи золотых билетов с 05 августа по 11 августа 2024 года
          до 23 часов 59 минут - дата определения победителей 19:00,{' '}
          <b>13 августа</b> 2024 года; <br />
          5. период выдачи золотых билетов с 12 августа по 18 августа 2024 года
          до 23 часов 59 минут - дата определения победителей 19:00,{' '}
          <b>20 августа</b> 2024 года. <br />
          6. период выдачи золотых билетов с 19 августа по 25 августа 2024 года
          до 23 часов 59 минут - дата определения победителей 19:00,{' '}
          <b>27 августа</b> 2024 года; <br />
          7. период выдачи золотых билетов с 26 августа по 1 сентября 2024 года
          до 23 часов 59 минут - дата определения победителей 19:00,{' '}
          <b>3 сентября</b> 2024 года. <br />
          8. период выдачи золотых билетов с 2 сентября по 8 сентября 2024 года
          до 23 часов 59 минут - дата определения победителей 19:00,{' '}
          <b>10 сентября</b> 2024 года. <br />
          <br />В розыгрышах принимают участие золотые билеты с уникальными
          кодами, полученные в указанные периоды. Не допускается к участию в
          розыгрыше коды, полученные ранее указанного периода.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.3. Определение будет проводиться путем случайного выпадения номеров
          победителей с помощью специализированного программного обеспечения,
          имеющего сертификат соответствия ПО, используемого для случайного
          выбора единичных данных из баз данных и прочих массивов информации в
          едином регистре. По итогам каждого розыгрыша на сайте Акции будут
          публиковаться выигрышные уникальные номерные коды золотых билетов и
          последние 4 цифры номера телефона.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.4. Участник, выигравший один приз Первого уровня в текущем
          розыгрыше, не может претендовать на такой же приз в последующих
          розыгрышах. Все золотые билеты с уникальными кодами, не выигравшие в
          текущем розыгрыше, аннулируются и не принимают участие в следующем.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.5. Призы Первого уровня не вручаются Победителям по следующим
          причинам: <br />
          - организатор не смог связаться с Участником и уведомить его о победе
          в прямом эфире розыгрыша. В таком случае победитель выбирается заново
          в рамках текущего прямого эфира; <br />
          - победитель отказался от приза или подписания Акта приемки-передачи
          или иных документов, связанных с вручением призов; <br />
          - победитель не смог приехать в центр выдачи призов в течении двух
          суток с момента определения победителей розыгрыша или не смог прислать
          своих делегатов с доверенностью на получение приза; <br />
          - победитель не выполнил какие-либо действия, необходимые для
          получения Приза и предусмотренные настоящим Положением либо совершил
          их с нарушением установленного срока; <br />
          - при проверке документов установлено, что Участник представил о себе
          неполную, искаженную или недостоверную информацию; <br />
          <br />
          Организатор не несет ответственности за правильность заполнения
          Участником его контактных данных при регистрации в программе
          лояльности «Клуб Друзей». <br />
          <br />
          Организатор вправе отказать в участии любому лицу без объяснения
          причин, если есть основания полагать, что такой Участник совершил
          неправомерные действия, которые повлияли на результат Акции или
          нарушил иные условия данного Положения.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.6. Организатор не несет ответственности в случае, если Победитель не
          явится в указанное время и дату для получения приза. В таком случае
          приз не вручается и остается у Организатора Акции и используется по
          своему усмотрению.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.7. Итоги еженедельных розыгрышей будут опубликованы на официальном
          аккаунте Организатора «SPAR Калининград» в социальной сети «Вконтакте»
          (https://vk.com/spar_kd) в течение 24-часов со дня проведения
          мероприятия по определению победителей.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.8. В момент проведения Мероприятия по определению победителей
          (прямого эфира на официальном аккаунте Организатора «SPAR Калининград»
          в социальной сети «Вконтакте» (https://vk.com/spar_kd)), согласно
          графику розыгрышей, указанному в п. 5.2. Организатор связывается с
          Победителями первой категории призов по номеру телефона, указанному
          при регистрации в программе лояльности «Клуб Друзей» и сообщает ему о
          его выигрыше. Если Победитель не выходит на связь после трёх звонков в
          период проведения прямого эфира, либо находится вне зоны доступа,
          такой победитель считается не вышедшим на связь и его приз
          переигрывается в рамках текущего розыгрыша.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.9. Призы могут отличаться по внешнему виду от их изображений на
          рекламно-информационных материалах.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.10. Ответственность Организатора Акции и Партнера Акции по выдаче
          призов ограничена исключительно вышеуказанным количеством и
          характеристиками. Все претензии относительно качества призов
          необходимо предъявлять непосредственно товаропроизводителю.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.11. Договор между Организатором и Участником Акции является
          безвозмездным, т.е. потенциальный Участник не вносит отдельной платы
          за участие в ней.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.12. Факт открытия лопатой острова на сайте программы лояльности
          Организатора friendsclub.ru или в мобильном приложении Клуба Друзей
          подразумевает, что данный Участник Акции ознакомлен и полностью
          согласен с настоящим Положением о проведении стимулирующей Акции,
          согласен на сбор обработку, хранение, Организатором и уполномоченными
          им лицами персональных данных Участника Акции, согласен на рассылку
          SMS рекламы, рекламы данной Акции, а также любой информации касающейся
          Акции. Кроме того, факт открытия лопатой остров на сайте программы
          лояльности Организатора friendsclub.ru или в мобильном приложении
          Клуба Друзей подтверждает согласие участника Акции в случае победы в
          одном из розыгрышей на трансляцию в прямом эфире аудио-сигнала
          телефонного звонка, на указанный при регистрации в программе
          лояльности номер мобильного телефона, представителя организатора Акции
          с целью информирования Участника о выпадении его номера, в социальных
          сетях организатора Акции и его партнеров.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.13. Обязанности по исчислению и уплате налогов, связанных с
          получением призов, а также ответственность за неисполнение этой
          обязанности Участники Акции (Победители) несут самостоятельно.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.14. Приз вручается в следующем порядке: <br />
          Победителям, в течение 2-х рабочих дней с даты оповещения необходимо
          предоставить по адресу г. Калининград, Люблинское шоссе, 6, офис 207{' '}
          <br />
          следующую информацию о себе: <br />
          - ФИО; <br />
          - почтовый адрес; <br />
          - копию страниц своего паспорта РФ (разворот с фотографией, страница с
          информацией о действующей прописке); <br />
          - копию свидетельства ИНН; <br />
          - копию СНИЛС; <br />
          - иную информация по запросу Организатора; <br />- наличие покупки, за
          которую были выданы электронные фишки в разделе «История дружбы»
          приложения «Клуба Друзей».
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.15. После получения от Участника информации, указанной в п.4.14.
          Положения, Организатор согласовывает дату, время и место вручения
          Приза.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          5.16. Победитель обязан подписать Акт приемки-передачи приза и иные
          документы, связанные с передачей ему приза.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          6. ПРАВА И ОБЯЗАННОСТИ УЧАСТНИКОВ АКЦИИ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.1. Участник Акции вправе требовать от Организатора Акции: <br />-
          получения информации об Акции в соответствии с правилами Акции; <br />
          - при соблюдении всех условий данного Положения, выдачи приза.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.2. Организатор настоящим Положением информируют Участника о том, что
          в соответствии с положениями Налогового Кодекса РФ стоимость Призов,
          полученных от организации превышающая 4 000 руб. за отчетный период
          (календарный год), в том числе полученных в натуральной форме,
          включается в налоговую базу по НДФЛ. Принимая участие в Акции и
          соглашаясь с настоящими правилами, Участники, в том числе Победители,
          считаются надлежащим образом проинформированными о вышеуказанной норме
          налогового законодательства РФ. Победитель обязан уплатить
          соответствующий налог на доходы физических лиц (НДФЛ) по ставке 35%.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.3. Участник Акции обязуется указывать точные, актуальные и
          достоверные данные при регистрации в программе лояльности «Клуб
          Друзей» согласно п. 2 настоящего Положения.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.4. Сумма НДФЛ составляет 35% от стоимости приза, указанной в
          акте-передачи приза.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.5. Организатор подает сведения в налоговую инспекцию в виде справки
          2-НДФЛ, о возможности удержания налога, т.к. приз будет выдан в
          натуральной стоимости.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.6. Покупатель должен самостоятельно уплатить НДФЛ от стоимости приза
          на официальном сайте Федеральной налоговой службы или через Единый
          портал государственных услуг.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          6.7. Принимая приз, покупатель даёт согласие на проведение съемки с
          его участием в момент передачи приза и публикацию фото и видео
          материалов с его изображением в социальных сетях и других рекламных
          материалах Организации.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          7. ПРАВА, ОБЯЗАННОСТИ И ОТВЕТСТВЕННОСТЬ ОРГАНИЗАТОРА
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.1. Организатор обязуется провести Акцию при технической поддержке,
          осуществляемой сайтом программы лояльности «Клуб Друзей»
          friendsclub.ru и мобильным приложением Клуба Друзей; а также
          обеспечить получение участниками призов в соответствии с условиями
          Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.2. Организатор не несет ответственность за неисполнение либо
          ненадлежащие исполнение своих обязательств, а также за какие-либо
          прямые, косвенные, особые потери Участников, связанные с участием в
          данной Акции, если неисполнение обязательств явилось следствием
          непредвидимых обстоятельств непреодолимой силы. Организатор не обязан
          возмещать потери участникам Акции в подобных случаях.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.3. Организатор имеет право отстранить Участника от участия в Акции
          на любом этапе проведения Акции, если возникли подозрения, что
          Участник (или кто-то другой за него) в ходе Акции нарушает условия
          акции или пытается изменить ее результаты посредством технических,
          программных или других средств, кроме способов, описанных в правилах
          проведения Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.4. Организатор не несет ответственности за любой ущерб, который
          возможно понесет Победитель вследствие использования им призов и/или
          участия в Акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.5. Организатор имеет право отказать Победителю в предоставление
          призов, если Победитель предоставил о себе неверную информацию.
          Предоставил ее несвоевременно или каким-либо другим образом нарушил
          настоящие правила проведения Акции. В случае проверки Победителя или
          участника акции на соблюдения условий акции Организатор вправе
          потребовать от Победителя или участника акции доказательства
          совершения покупок в магазинах участниках акции (выписки с банковских
          карт, копии чеков и т.д.). Если Победитель или участник акции не может
          доказать факт покупки (соблюдения условий акции) Организатор вправе
          отказать в получении приза и переиграть приз, а также признать факт
          нарушения Участником условий акции.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.6. Организатор не несет ответственности за непредоставление
          Участником необходимых сведений для получения Приза, в том числе по
          вине почтовой службы, организации связи. За технические проблемы и/или
          мошенничества в сети Интернет и/или каналов связи, используемых при
          проведении Акции, а также за невозможность осуществления связи с
          Участником из-за указанных неверных или неактуальных контактных
          данных, в том числе номера телефона. ФИО участника и иных данных, а
          также технических проблем, возникших в момент регистрации в программе
          лояльности или пробития чека в магазине.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.7. Организатор Акции не несет ответственность за технические
          неполадки на сайте программы лояльности «Клуб Друзей» friendsclub.ru и
          мобильном приложении «Клуб Друзей», в результате которых может
          возникнуть сбой в их работе.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.8. Организатор Акции оставляет за собой право не вступать в
          письменные переговоры либо иные контакты с Участниками Акции, кроме
          случаев, предусмотренных настоящим Положением, действующим
          законодательством РФ.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.9. Организатор Акции имеет право на свое усмотрение в одностороннем
          порядке прекратить проведение Акции или изменить её условия, если по
          какой-то причине любой аспект настоящей Акции не может проводиться
          так, как это запланировано.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.10. Данное Положение является единственными официальными правилами
          участия в Акции. В случае возникновения ситуаций, допускающих
          неоднозначное толкование данных правил, окончательное решение о таком
          толковании принимается исключительно Организатором Акции в
          соответствии с законодательством РФ.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          7.11. Организатор имеет право сделать фото- и видео-сессию Победителя
          Акции с Призом и разместить эту информацию в СМИ, на сайте программы
          лояльности «Клуб Друзей» friendsclub.ru и в мобильном приложении «Клуб
          Друзей», а также во всех социальных сетях компании.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          8. ПЕРСОНАЛЬНЫЕ ДАННЫЕ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          8.1. Принимая участие в Акции и добровольно предоставляя свои
          персональные данные, Участник подтверждает свое согласие на обработку
          Организатором Акции предоставленных данных, включая сбор, запись,
          систематизацию, накопление, использование, хранение, уточнение,
          обновление, изменение, извлечение, использование, передачу
          распространение, обезличивание, блокирование, удаление, уничтожение
          для целей проведения Акции на весь срок ее проведения, в соответствии
          с положениями предусмотренными Федеральным законом РФ № 152-ФЗ от 27
          июля 2006г. «О персональных данных (далее – Закон).
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          8.2. Участники понимают и соглашаются с тем, что персональные данные,
          указанные/предоставленные ими для участия в Акции, будут
          обрабатываться всеми необходимыми способами в целях проведения Акции,
          иными целями в соответствии с настоящим Положением, и дают свое
          согласие на такую обработку.
        </Text>

        <Text
          {...contentTextProps}
          mb="32px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          8.3. Принимая участие в Акции, Участники подтверждают свое согласие
          тем, что Организатор и уполномоченные им лица, которые будут соблюдать
          необходимые меры защиты таких данных от несанкционированного
          распространения, вправе осуществлять сбор, систематизацию, хранение, а
          также иным способом обрабатывать персональные данные Участника,
          который Участник предоставляет по запросу Организатора Акции-данные
          паспорта гражданина РФ: <br />
          - ФИО; <br />
          - дата и место рождения; <br />
          - серия и номер паспорта, дата выдачи. Наименование выдавшего органа,
          код подразделения; <br />
          - адрес регистрации по месту жительства; <br />
          - номер ИНН. <br />
          Вышеперечисленные персональные данные получаются и обрабатываются
          Организатором исключительно в целях, проведения настоящей Акции, для
          выдачи приза в случае выигрыша. <br />
          <br />
          Согласие действительно с момента регистрации уникального кода на сайте
          программы лояльности «Клуб Друзей» friendsclub.ru или в мобильном
          приложении «Клуб Друзей». <br />
          <br />
          Персональные данные Участника хранятся в базе Организатора в течение
          срока проведения Акции. Субъект персональных данных вправе отозвать
          свое согласие, отправив электронное письмо Организатору.
        </Text>

        <Text
          {...headingTextProps}
          as="h2"
          mb="32px"
          fontSize="24px"
          lineHeight="22px"
          textAlign="center"
          color="#fff"
        >
          9. ПРОЧЕЕ
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          9.1. Любое время, указанное в настоящих Правилах, считается
          по-местному Калининградскому времени.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          9.2. Во всех ситуациях, не предусмотренным настоящим Положением,
          Организатор и Участники руководствуются действующим законодательством
          РФ.
        </Text>

        <Text
          {...contentTextProps}
          mb="16px"
          fontSize="17px"
          lineHeight="22px"
          color="#fff"
        >
          9.3. Стороны освобождаются от ответственности за невыполнение или
          ненадлежащие выполнение своих обязательств, если такое невыполнение
          явилось результатом действия непреодолимой силы.
        </Text>
      </LayoutMobile.Content>
    </LayoutMobile>
  </GlobalStyle>
)
