import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { PATHS } from '../../routing/paths'
import { Box, Button, TextTitle } from '../../ui/atoms'
import { Notice } from '../../ui/organisms'
import { LayoutMobile } from '../../ui/layout'
import { config } from 'config'

const TextTitleHome = styled(TextTitle)`
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.textTertiaryColor}`};
`

export const HomeMobile = () => {
  return (
    <LayoutMobile {...config.backgrounds.HOME_MOBILE_BACKGROUND}>
      <LayoutMobile.Content>
        <Notice mb={{ _: '50px', md: '28px' }} />

        <Box
          alt={config.texts.homePage.headingAlt}
          maxWidth={{ _: '100%', md: '486px' }}
          mb={{ _: '50px', md: '58px' }}
          style={{ textAlign: 'center' }}
        >
          {config.texts.homePage.headingMobile.map((textLine, index) => {
            return (
              <TextTitleHome fontSize="28px" key={index}>
                {textLine}
              </TextTitleHome>
            )
          })}
        </Box>

        <Box maxWidth={{ md: '486px' }}>
          <Link to={PATHS.CALENDARS}>
            <Button
              as="span"
              variant="primary"
              w="100%"
              px={{ _: '16px', xs: '20px' }}
              py={{ _: '12px', xs: '16px' }}
              mb="16px"
              fontSize={{ _: '16px', xs: '20px' }}
              boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
            >
              {config.texts.homePage.toCalendars}
            </Button>
          </Link>

          <Link to={PATHS.TICKETS}>
            <Button
              as="span"
              variant="default"
              w="100%"
              px={{ _: '16px', xs: '20px' }}
              py={{ _: '12px', xs: '16px' }}
              fontSize={{ _: '16px', xs: '20px' }}
              boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
            >
              Мои билеты
            </Button>
          </Link>
        </Box>
      </LayoutMobile.Content>
    </LayoutMobile>
  )
}
