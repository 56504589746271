import { keysBoxWrapperPropsNewYearGame } from './keysBoxWrapperPropsNewYearGame'
import { keysBoxWrapperPropsTreasureIslandGame } from './keysBoxWrapperPropsTreasureIslandGame'

const keysBoxWrapperPropsByGameId = {
  1: keysBoxWrapperPropsNewYearGame,
  2: keysBoxWrapperPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keysBoxWrapperProps = keysBoxWrapperPropsByGameId[gameId]
