import { useMobileBackgroundNewYearGame } from './useMobileBackgroundNewYearGame'
import { useMobileBackgroundTreasureIslandGame } from './useMobileBackgroundTreasureIslandGame'

const useMobileBackgroundByGameId = {
  1: useMobileBackgroundNewYearGame,
  2: useMobileBackgroundTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useMobileBackground = useMobileBackgroundByGameId[gameId]
