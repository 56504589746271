import { Box, Button, LinkWithQuery, Text } from 'ui/atoms'
import { PopupInfo } from 'ui/molecules'

import { PATHS } from 'routing/paths'
import { config } from 'config'
import { headingTextProps } from './headingTextProps'
import { contentTextProps } from './contentTextProps'
import { buttonProps } from './buttonProps'

export const PopupRules = ({ isOpened, onClose }) => (
  <PopupInfo variant="default" isOpened={isOpened} onClose={onClose}>
    <Box {...headingTextProps}>
      {config.texts.popups.rules.heading.map((textLine, index) => {
        return <div key={index}>{textLine}</div>
      })}
    </Box>
    <Text {...contentTextProps}>
      Прежде чем отправиться в путь, ознакомься с&nbsp;
      {/* Прежде чем начать игру, ознакомься с{' '} */}
      <LinkWithQuery to={PATHS.RULES}>
        <Text as="span" textDecoration={{ _: 'underline', hover: 'none' }}>
          правилами акции
        </Text>
      </LinkWithQuery>
      .
    </Text>
    <Button {...buttonProps} onClick={onClose}>
      Продолжить
    </Button>
  </PopupInfo>
)
