import { headingTextPropsNewYearGame } from './headingTextPropsNewYearGame'
import { headingTextPropsTreasureIslandGame } from './headingTextPropsTreasureIslandGame'

const headingTextPropsByGameId = {
  1: headingTextPropsNewYearGame,
  2: headingTextPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const headingTextProps = headingTextPropsByGameId[gameId]
