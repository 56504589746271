import { BoardSceneWrapperNewYearGame } from './BoardSceneWrapperNewYearGame'
import { BoardSceneWrapperTreasureIslandGame } from './BoardSceneWrapperTreasureIslandGame'

const BoardSceneWrapperByGameId = {
  1: BoardSceneWrapperNewYearGame,
  2: BoardSceneWrapperTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const BoardSceneWrapper = BoardSceneWrapperByGameId[gameId]
