import { promocodeTextPropsNewYearGame } from './promocodeTextPropsNewYearGame'
import { promocodeTextPropsTreasureIslandGame } from './promocodeTextPropsTreasureIslandGame'

const promocodeTextPropsByGameId = {
  1: promocodeTextPropsNewYearGame,
  2: promocodeTextPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const promocodeTextProps = promocodeTextPropsByGameId[gameId]
