import { CaptionTextNewYearGame } from './CaptionTextNewYearGame'
import { CaptionTextTreasureIslandGame } from './CaptionTextTreasureIslandGame'

const CaptionTextByGameId = {
  1: CaptionTextNewYearGame,
  2: CaptionTextTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const CaptionText = CaptionTextByGameId[gameId]
