import React from 'react'
import styled from 'styled-components'

import { Box, Flex } from 'ui/atoms'
import { LayoutMobile } from 'ui/layout'
import { config } from 'config'
import { TextTitleRaffleWinner } from './TextTitleRaffleWinner'
import { RaffleWinnerItemText } from './RaffleWinnerItemText'

const RaffleWinnerItemImg = styled.img`
  width: 100%;
  margin: 0 auto;
`

const RaffleWinnerItem = ({ img, text }) => {
  return (
    <Flex flexDirection="column" alignItems="center" mb="20px">
      <RaffleWinnerItemText>{text}</RaffleWinnerItemText>
      <Box borderRadius="10px" overflow="hidden" maxWidth="700px">
        <RaffleWinnerItemImg src={img} alt="" />
      </Box>
    </Flex>
  )
}

export const RaffleWinnersMobile = React.forwardRef(
  ({ tickets, isLoading, hasError, onRetry, isWideViewport }, ref) => {
    return (
      <LayoutMobile
        ref={ref}
        {...config.backgrounds.SPONSORS_MOBILE_BACKGROUND}
      >
        <LayoutMobile.Header mb="16px">
          <TextTitleRaffleWinner
            fontSize="40px"
            fontWeight="500"
            lineHeight="130%"
            letterSpacing="0.5px"
            textAlign="center"
            mx="auto"
          >
            Победители
          </TextTitleRaffleWinner>
        </LayoutMobile.Header>

        <LayoutMobile.Content>
          <RaffleWinnerItem
            text="5 декабря - 11 декабря"
            img="/img/winners_stub.png"
          />
          <RaffleWinnerItem
            text="12 декабря - 18 декабря"
            img="/img/winners_stub.png"
          />
          <RaffleWinnerItem
            text="19 декабря - 25 декабря"
            img="/img/winners_stub.png"
          />
        </LayoutMobile.Content>
      </LayoutMobile>
    )
  }
)
