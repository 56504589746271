import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  firstVisit: null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUrlParams: (state, { payload }) => {
      state.urlSearchParams = { ...state.urlSearchParams, ...payload }
    },
    clearUrlParams: state => {
      state.urlSearchParams = {}
    },
    setIsFirstVisit: (state, { payload }) => {
      if (state.firstVisit) {
        state.firstVisit.isFirstVisit = payload
      }
    },
    setFirstVisit: (state, { payload }) => {
      state.firstVisit = payload
    },
  },
})

export const { setUrlParams, clearUrlParams, setIsFirstVisit, setFirstVisit } =
  userSlice.actions

export const { reducer: userReducer } = userSlice

export default userSlice
