import * as ticketsMobileConstantsNewYearGame from './ticketsMobileConstantsNewYearGame'
import * as ticketsMobileConstantsTreasureIslandGame from './ticketsMobileConstantsTreasureIslandGame'

const ticketsMobileContantsByGameId = {
  1: ticketsMobileConstantsNewYearGame,
  2: ticketsMobileConstantsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketsMobileContants = ticketsMobileContantsByGameId[gameId]
