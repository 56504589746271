import { Button, Text } from 'ui/atoms'
import { PopupInfo } from 'ui/molecules'
import { headingProps } from './headingProps'
import { contentProps } from './contentProps'
import { buttonProps } from './buttonProps'
import { config } from 'config'

export const PopupNotHaveKeys = ({ isOpened, onClose }) => (
  <PopupInfo logoVariant="primary" isOpened={isOpened} onClose={onClose}>
    <Text {...headingProps}>{config.texts.popups.notHaveKeys.heading}</Text>
    <Text {...contentProps}>
      Покупай на сумму от 800₽ или товары-спонсоры в магазинах SPAR, Победа и
      Семья, чтобы получить их
    </Text>

    <Button {...buttonProps} onClick={onClose}>
      Продолжить
    </Button>
  </PopupInfo>
)
