import { congratulationsTextPropsNewYearGame } from './congratulationsTextPropsNewYearGame'
import { congratulationsTextPropsTreasureIslandGame } from './congratulationsTextPropsTreasureIslandGame'

const congratulationsTextPropsByGameId = {
  1: congratulationsTextPropsNewYearGame,
  2: congratulationsTextPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const congratulationsTextProps = congratulationsTextPropsByGameId[gameId]
