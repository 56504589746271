import { TextPageTitleNewYearGame } from './TextPageTitleNewYearGame'
import { TextPageTitleTreasureIslandGame } from './TextPageTitleTreasureIslandGame'

const TextPageTitleByGameId = {
  1: TextPageTitleNewYearGame,
  2: TextPageTitleTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextPageTitle = TextPageTitleByGameId[gameId]
