import { Button, Text } from 'ui/atoms'
import { PopupInfo } from 'ui/molecules'
import { buttonProps } from './buttonProps'
import { headingProps } from './headingProps'
import { popupInfoProps } from './popupInfoProps'
import { contentProps } from './contentProps'
import { config } from 'config'

export const PopupAllDaysOpened = ({ isOpened, onClose, onSubmitClick }) => (
  <PopupInfo isOpened={isOpened} onClose={onClose} {...popupInfoProps}>
    <Text {...headingProps}>{config.texts.popups.allDaysOpened.heading}</Text>
    <Text {...contentProps}>{config.texts.popups.allDaysOpened.content}</Text>

    <Button {...buttonProps} onClick={onSubmitClick}>
      Перейти
    </Button>
  </PopupInfo>
)
