import { KeysCarouselControlNewYearGame } from './KeysCarouselControlNewYearGame'
import { KeysCarouselControlTreasureIslandGame } from './KeysCarouselControlTreasureIslandGame'

const KeysCarouselControlByGameId = {
  1: KeysCarouselControlNewYearGame,
  2: KeysCarouselControlTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeysCarouselControl = KeysCarouselControlByGameId[gameId]
