import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { calendarDetailsFetch } from 'store/slices'

export const useCalendarManager = () => {
  const calendarDetails = useSelector(
    state => state.calendar.calendarDetailsData
  )
  const isCalendarDetailsLoading = useSelector(
    state => state.calendar.calendarDetailsLoading
  )
  const calendarDetailsError = useSelector(
    state => state.calendar.calendarDetailsError
  )

  const giftReceivingError = useSelector(
    state => state.calendar.giftReceivingError
  )

  const dispatch = useDispatch()

  const fetchCalendarDetails = useCallback(
    id => dispatch(calendarDetailsFetch(id)),
    [dispatch]
  )

  return {
    calendarDetails,
    isCalendarDetailsLoading,
    calendarDetailsError,
    giftReceivingError,
    fetchCalendarDetails,
  }
}
