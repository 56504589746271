import { yourGiftTextPropsNewYearGame } from './yourGiftTextPropsNewYearGame'
import { yourGiftTextPropsTreasureIslandGame } from './yourGiftTextPropsTreasureIslandGame'

const yourGiftTextPropsByGameId = {
  1: yourGiftTextPropsNewYearGame,
  2: yourGiftTextPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const yourGiftTextProps = yourGiftTextPropsByGameId[gameId]
