import { RaffleWinnerItemTextNewYearGame } from './RaffleWinnerItemTextNewYearGame'
import { RaffleWinnerItemTextTreasureIslandGame } from './RaffleWinnerItemTextTreasureIslandGame'

const RaffleWinnerItemTextByGameId = {
  1: RaffleWinnerItemTextNewYearGame,
  2: RaffleWinnerItemTextTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const RaffleWinnerItemText = RaffleWinnerItemTextByGameId[gameId]
