import { useEffect } from 'react'
import useMatchMedia from 'react-use-match-media'

import { usePopupState } from '../../hooks/usePopupState'
import { useUserFirstVisit } from '../../hooks/useUserFirstVisit'
import { useKeysManager } from '../../hooks/useKeysManager'

import {
  PopupRules,
  PopupActionEnd,
  PopupKeysReminder,
} from '../../ui/organisms'
import { HomeDesktop } from './HomeDesktop'
import { HomeMobile } from './HomeMobile'
import { GlobalStyle } from 'ui/settings/global'
import { useUserManager } from 'hooks/useUserManager'
import { useCalendarsManager } from 'hooks/useCalendarsManager'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'routing/routes'
import { CALENDAR_STATUS } from 'constants/calendarGifts'
import { usePopupsManager } from 'hooks/usePopupsManager'

export const Home = () => {
  const navigate = useNavigate()
  const {
    isKeysRemiderPopupShownInitially,
    setKeysRemiderPopupShownInitiallyTruthy,
  } = usePopupsManager()
  const { keysCount, fetchKeys } = useKeysManager({ isAutoLoad: false })
  const { urlSearchParams } = useUserManager()
  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const {
    isOpen: isRulesPopupOpen,
    onOpen: onRulesPopupOpen,
    onClose: onRulesPopupClose,
  } = usePopupState()
  const {
    isOpen: isReminderPopupOpen,
    onOpen: onReminderPopupOpen,
    onClose: onReminderPopupClose,
  } = usePopupState()
  // const {
  //   isOpen: isPromotionPopupOpen,
  //   onOpen: onPromotionPopupOpen,
  //   onClose: onPromotionPopupClose,
  // } = usePopupState({ initialOpen: true })
  const { isFirstVisit } = useUserFirstVisit(onRulesPopupOpen)
  const { calendars } = useCalendarsManager()

  useEffect(() => {
    if (!!urlSearchParams?.session_id && !!urlSearchParams?.user_id) {
      fetchKeys()
    }
  }, [fetchKeys, urlSearchParams])

  useEffect(() => {
    if (
      keysCount > 0 &&
      isFirstVisit === false &&
      !isKeysRemiderPopupShownInitially
    ) {
      onReminderPopupOpen()
      setKeysRemiderPopupShownInitiallyTruthy()
    }
  }, [keysCount])

  const rulesPopupCloseHandler = () => {
    onRulesPopupClose()

    if (keysCount > 0 && !isKeysRemiderPopupShownInitially) {
      onReminderPopupOpen()
      setKeysRemiderPopupShownInitiallyTruthy()
    }
  }

  const onPopupKeysReminderSubmit = () => {
    if (!!calendars) {
      const calendar = calendars.find(calendar => {
        return calendar.status !== CALENDAR_STATUS.FINISHED
      })
      if (!!calendar) {
        navigate(ROUTES.CALENDAR.replace('[calendarId]', calendar.id))
      } else {
        onReminderPopupClose()
      }
    }
  }

  return (
    <GlobalStyle>
      {isWideViewport ? <HomeDesktop /> : <HomeMobile />}
      <PopupRules
        isOpened={isRulesPopupOpen}
        onClose={rulesPopupCloseHandler}
      />
      <PopupKeysReminder
        keysCount={keysCount}
        isOpened={isReminderPopupOpen}
        onClose={onReminderPopupClose}
        onSubmitClick={onPopupKeysReminderSubmit}
      />

      {/* <PopupActionEnd
        isOpened={isPromotionPopupOpen}
        onClose={onPromotionPopupClose}
      /> */}
    </GlobalStyle>
  )
}
