import { PopupLogoNewYearGame } from './PopupLogoNewYearGame'
import { PopupLogoTreasureIslandGame } from './PopupLogoTreasureIslandGame'

const PopupLogoByGameId = {
  1: PopupLogoNewYearGame,
  2: PopupLogoTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupLogo = PopupLogoByGameId[gameId]
