import { raffleDateTextPropsNewYearGame } from './raffleDateTextPropsNewYearGame'
import { raffleDateTextPropsTreasureIslandGame } from './raffleDateTextPropsTreasureIslandGame'

const raffleDateTextPropsByGameId = {
  1: raffleDateTextPropsNewYearGame,
  2: raffleDateTextPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const raffleDateTextProps = raffleDateTextPropsByGameId[gameId]
