import { goldenTicketsPropsNewYearGame } from './goldenTicketsPropsNewYearGame'
import { goldenTicketsPropsTreasureIslandGame } from './goldenTicketsPropsTreasureIslandGame'

const goldenTicketsPropsByGameId = {
  1: goldenTicketsPropsNewYearGame,
  2: goldenTicketsPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const goldenTicketsProps = goldenTicketsPropsByGameId[gameId]
