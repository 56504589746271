import { TextTitleSponsorsNewYearGame } from './TextTitleSponsorsNewYearGame'
import { TextTitleSponsorsTreasureIslandGame } from './TextTitleSponsorsTreasureIslandGame'

const TextTitleSponsorsByGameId = {
  1: TextTitleSponsorsNewYearGame,
  2: TextTitleSponsorsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleSponsors = TextTitleSponsorsByGameId[gameId]
