import { useWideViewportNewYearGame } from './useWideViewportNewYearGame'
import { useWideViewportTreasureIslandGame } from './useWideViewportTreasureIslandGame'

const useWideViewportByGameId = {
  1: useWideViewportNewYearGame,
  2: useWideViewportTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useWideViewport = useWideViewportByGameId[gameId]
