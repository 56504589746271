import { headingPropsNewYearGame } from './headingPropsNewYearGame'
import { headingPropsTreasureIslandGame } from './headingPropsTreasureIslandGame'

const headingPropsByGameId = {
  1: headingPropsNewYearGame,
  2: headingPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const headingProps = headingPropsByGameId[gameId]
