import { PopupInfoPanelNewYearGame } from './PopupInfoPanelNewYearGame'
import { PopupInfoPanelTreasureIslandGame } from './PopupInfoPanelTreasureIslandGame'

const PopupInfoPanelByGameId = {
  1: PopupInfoPanelNewYearGame,
  2: PopupInfoPanelTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupInfoPanel = PopupInfoPanelByGameId[gameId]
