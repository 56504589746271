import { ticketRafflePropsNewYearGame } from './ticketRafflePropsNewYearGame'
import { ticketRafflePropsTreasureIslandGame } from './ticketRafflePropsTreasureIslandGame'

const ticketRafflePropsByGameId = {
  1: ticketRafflePropsNewYearGame,
  2: ticketRafflePropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketRaffleProps = ticketRafflePropsByGameId[gameId]
