import { TicketListItemNewYearGame } from './TicketListItemNewYearGame'
import { TicketListItemTreasureIslandGame } from './TicketListItemTreasureIslandGame'

const TicketListItemByGameId = {
  1: TicketListItemNewYearGame,
  2: TicketListItemTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TicketListItem = TicketListItemByGameId[gameId]
