import { PopupInfoCloseNewYearGame } from './PopupInfoCloseNewYearGame'
import { PopupInfoCloseTreasureIslandGame } from './PopupInfoCloseTreasureIslandGame'

const PopupInfoCloseByGameId = {
  1: PopupInfoCloseNewYearGame,
  2: PopupInfoCloseTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupInfoClose = PopupInfoCloseByGameId[gameId]
