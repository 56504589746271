import { contentSectionTopPropsNewYearGame } from './contentSectionTopPropsNewYearGame'
import { contentSectionTopPropsTreasureIslandGame } from './contentSectionTopPropsTreasureIslandGame'

const contentSectionTopPropsByGameId = {
  1: contentSectionTopPropsNewYearGame,
  2: contentSectionTopPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const contentSectionTopProps = contentSectionTopPropsByGameId[gameId]
