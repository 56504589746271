import { KeyDescriptionWrapperNewYearGame } from './KeyDescriptionWrapperNewYearGame'
import { KeyDescriptionWrapperTreasureIslandGame } from './KeyDescriptionWrapperTreasureIslandGame'

const KeyDescriptionWrapperByGameId = {
  1: KeyDescriptionWrapperNewYearGame,
  2: KeyDescriptionWrapperTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeyDescriptionWrapper = KeyDescriptionWrapperByGameId[gameId]
