import { NavbarDesktopWrapperNewYearGame } from './NavbarDesktopWrapperNewYearGame'
import { NavbarDesktopWrapperTreasureIslandGame } from './NavbarDesktopWrapperTreasureIslandGame'

const NavbarDesktopWrapperByGameId = {
  1: NavbarDesktopWrapperNewYearGame,
  2: NavbarDesktopWrapperTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const NavbarDesktopWrapper = NavbarDesktopWrapperByGameId[gameId]
