import { useBgImageNewYearGame } from './useBgImageNewYearGame'
import { useBgImageTreasureIslandGame } from './useBgImageTreasureIslandGame'

const useBgImageByGameId = {
  1: useBgImageNewYearGame,
  2: useBgImageTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useBgImage = useBgImageByGameId[gameId]
