import { contentSectionBottomPropsNewYearGame } from './contentSectionBottomPropsNewYearGame'
import { contentSectionBottomPropsTreasureIslandGame } from './contentSectionBottomPropsTreasureIslandGame'

const contentSectionBottomPropsByGameId = {
  1: contentSectionBottomPropsNewYearGame,
  2: contentSectionBottomPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const contentSectionBottomProps =
  contentSectionBottomPropsByGameId[gameId]
