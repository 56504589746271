import { mainMenuWrapperPropsNewYearGame } from './mainMenuWrapperPropsNewYearGame'
import { mainMenuWrapperPropsTreasureIslandGame } from './mainMenuWrapperPropsTreasureIslandGame'

const mainMenuWrapperPropsByGameId = {
  1: mainMenuWrapperPropsNewYearGame,
  2: mainMenuWrapperPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const mainMenuWrapperProps = mainMenuWrapperPropsByGameId[gameId]
