import { titleTextPropsNewYearGame } from './titleTextPropsNewYearGame'
import { titleTextPropsTreasureIslandGame } from './titleTextPropsTreasureIslandGame'

const titleTextPropsByGameId = {
  1: titleTextPropsNewYearGame,
  2: titleTextPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const titleTextProps = titleTextPropsByGameId[gameId]
