import { TextTitleRaffleWinnerNewYearGame } from './TextTitleRaffleWinnerNewYearGame'
import { TextTitleRaffleWinnerTreasureIslandGame } from './TextTitleRaffleWinnerTreasureIslandGame'

const TextTitleRaffleWinnerByGameId = {
  1: TextTitleRaffleWinnerNewYearGame,
  2: TextTitleRaffleWinnerTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleRaffleWinner = TextTitleRaffleWinnerByGameId[gameId]
