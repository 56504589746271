import { config } from 'config'
import { Box, Button, Text } from 'ui/atoms'
import { PopupInfo } from 'ui/molecules'
import theme from 'ui/settings/theme'
import { headingProps } from './headingProps'
import { contentSectionTopProps } from './contentSectionTopProps'
import { contentSectionBottomProps } from './contentSectionBottomProps'
import { keysProps } from './keysProps'
import { keyImgProps } from './keyImgProps'
import { buttonProps } from './buttonProps'

export const PopupKeysReminder = ({
  keysCount,
  isOpened,
  onClose,
  onSubmitClick,
}) => (
  <PopupInfo
    logoVariant="primary"
    isOpened={isOpened}
    onClose={onClose}
    hasCloseBtn
  >
    <Box {...headingProps}>
      {config.texts.popups.keysReminder.heading.map((textLine, index) => {
        return <div key={index}>{textLine}</div>
      })}
    </Box>
    <Text {...contentSectionTopProps}>
      {config.texts.popups.keysReminder.contentSectionTop}
    </Text>
    <Text {...contentSectionBottomProps}>
      {config.texts.popups.keysReminder.contentSectionBottom}
    </Text>

    <Box mb="20px">
      <Box {...keyImgProps} />
      <Text {...keysProps}>
        {config.texts.popups.keysReminder.keys}: {keysCount}
      </Text>
    </Box>

    <Button {...buttonProps} onClick={onSubmitClick}>
      {config.texts.popups.keysReminder.button}
    </Button>
  </PopupInfo>
)
