import { contentPropsNewYearGame } from './contentPropsNewYearGame'
import { contentPropsTreasureIslandGame } from './contentPropsTreasureIslandGame'

const contentPropsByGameId = {
  1: contentPropsNewYearGame,
  2: contentPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const contentProps = contentPropsByGameId[gameId]
