import { textDescriptionPropsNewYearGame } from './textDescriptionPropsNewYearGame'
import { textDescriptionPropsTreasureIslandGame } from './textDescriptionPropsTreasureIslandGame'

const textDescriptionPropsByGameId = {
  1: textDescriptionPropsNewYearGame,
  2: textDescriptionPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const textDescriptionProps = textDescriptionPropsByGameId[gameId]
