import theme from 'ui/settings/theme'
import { Box, Button, Text } from 'ui/atoms'
import { PopupFortuneGiftBase } from 'ui/molecules'
import { buttonProps } from './buttonProps'
import { headingTextProps } from './headingTextProps'
import { congratulationsTextProps } from './congratulationsTextProps'
import { fortuneCaptionTextProps } from './fortuneCaptionTextProps'
import { fortuneValueTextProps } from './fortuneValueTextProps'
import { config } from 'config'

export const PopupFortuneGift = ({ fortuneValue, isOpened, onClose }) => (
  <PopupFortuneGiftBase isOpened={isOpened} onClose={onClose}>
    <Box {...headingTextProps}>
      {config.texts.popups.fortuneGift.heading.map((textLine, index) => {
        return <div key={index}>{textLine}</div>
      })}
    </Box>
    <Text {...congratulationsTextProps}>Поздравляем! Ты получил</Text>
    <Box
      w="257px"
      p="16px 8px 16px 8px"
      borderRadius="12px"
      m="0 auto 20px auto"
      backgroundColor="#ffffff"
    >
      <Text {...fortuneCaptionTextProps}>Предсказание:</Text>
      <Text {...fortuneValueTextProps}>«{fortuneValue}»</Text>
    </Box>
    <Button {...buttonProps} onClick={onClose}>
      Продолжить
    </Button>
  </PopupFortuneGiftBase>
)
