import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setFirstVisit } from 'store/slices'

export const useUserFirstVisit = handler => {
  const dispatch = useDispatch()
  const firstVisit = useSelector(state => state.user.firstVisit)

  useEffect(() => {
    if (
      firstVisit === undefined ||
      firstVisit?.id !== process.env.REACT_APP_GAME_ID
    ) {
      handler && handler()
      dispatch(
        setFirstVisit({
          id: process.env.REACT_APP_GAME_ID,
          isFirstVisit: true,
        })
      )
    } else if (
      firstVisit?.id === process.env.REACT_APP_GAME_ID &&
      firstVisit?.isFirstVisit
    ) {
      handler && handler()
    }
  }, [])

  return { isFirstVisit: firstVisit?.isFirstVisit }
}
