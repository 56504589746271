import { fortuneCaptionTextPropsNewYearGame } from './fortuneCaptionTextPropsNewYearGame'
import { fortuneCaptionTextPropsTreasureIslandGame } from './fortuneCaptionTextPropsTreasureIslandGame'

const fortuneCaptionTextPropsPropsByGameId = {
  1: fortuneCaptionTextPropsNewYearGame,
  2: fortuneCaptionTextPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const fortuneCaptionTextProps =
  fortuneCaptionTextPropsPropsByGameId[gameId]
