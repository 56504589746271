import { keysPropsNewYearGame } from './keysPropsNewYearGame'
import { keysPropsTreasureIslandGame } from './keysPropsTreasureIslandGame'

const keysPropsByGameId = {
  1: keysPropsNewYearGame,
  2: keysPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keysProps = keysPropsByGameId[gameId]
