import { fontsNewYearGame } from './fontsNewYearGame'
import { fontsTreasureIslandGame } from './fontsTreasureIslandGame'

const fontsByGameId = {
  1: fontsNewYearGame,
  2: fontsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const fonts = fontsByGameId[gameId]
