import { keysInfoWrapperPropsDesktopNewYearGame } from './keysInfoWrapperPropsDesktopNewYearGame'
import { keysInfoWrapperPropsDesktopTreasureIslandGame } from './keysInfoWrapperPropsDesktopTreasureIslandGame'

const keysInfoWrapperPropsDesktopByGameId = {
  1: keysInfoWrapperPropsDesktopNewYearGame,
  2: keysInfoWrapperPropsDesktopTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keysInfoWrapperPropsDesktop =
  keysInfoWrapperPropsDesktopByGameId[gameId]
