import { routesNewYearGame } from './routesNewYearGame'
import { routesTreasureIslandGame } from './routesTreasureIslandGame'

const routesByGameId = {
  1: routesNewYearGame,
  2: routesTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ROUTES = routesByGameId[gameId]
