import { ticketValueTextPropsNewYearGame } from './ticketValueTextPropsNewYearGame'
import { ticketValueTextPropsTreasureIslandGame } from './ticketValueTextPropsTreasureIslandGame'

const ticketValueTextPropsByGameId = {
  1: ticketValueTextPropsNewYearGame,
  2: ticketValueTextPropsTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketValueTextProps = ticketValueTextPropsByGameId[gameId]
