import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/ru'

dayjs.extend(weekOfYear)
dayjs.extend(isBetween)
dayjs.extend(customParseFormat)
dayjs.locale('ru')

export const groupCouponsByYear = coupon => dayjs(coupon.date).year()

export const groupCouponsByWeek = coupon => dayjs(coupon.date).week()

export const weekDatesRange = couponDate => {
  const firstDay = dayjs(couponDate).startOf('week')
  const lastDay = dayjs(couponDate).endOf('week')
  const isSameMonth = dayjs(firstDay).isSame(dayjs(lastDay), 'month')
  const firstDayFormat = isSameMonth ? 'D' : 'D MMM'

  return `${firstDay.format(firstDayFormat)} - ${lastDay.format('D MMMM')}`
}

export const getClosestRaffleDate = (date, terms) => {
  const firstTerm = terms[0]
  const lastTerm = terms[terms.length - 1]

  if (dayjs(date).isBefore(firstTerm.from)) {
    return firstTerm.raffle
  }

  if (dayjs(date).isAfter(lastTerm.to)) {
    return lastTerm.raffle
  }

  const filteredTerm = terms.filter(d =>
    dayjs(date).isBetween(dayjs(d.from), dayjs(d.to))
  )

  return filteredTerm[0]?.raffle || null
}
