import styled from 'styled-components'
import { Box, Button, Text } from 'ui/atoms'
import { PopupPromocodeGiftBase } from 'ui/molecules'
import theme from 'ui/settings/theme'
import { copyTextToClipboard } from 'utils/copyTextToClipboard'
import { headingTextProps } from './headingTextProps'
import { congratulationsTextProps } from './congratulationsTextProps'
import { buttonProps } from './buttonProps'
import { promocodeTextProps } from './promocodeTextProps'
import { ActionDescriptionWrapper } from './ActionDescriptionWrapper'
import { copyTextProps } from './copyTextProps'
import { config } from 'config'

export const PopupPromocodeGift = ({
  promocodeValue,
  promocodeDescription,
  isOpened,
  onClose,
}) => {
  const onCopyPromocodeClick = () => {
    const promocodeRegex = RegExp('<b>(.*?)</b>', 'i')
    const promocode = promocodeValue.match(promocodeRegex)[1]
    if (!!promocode) {
      copyTextToClipboard(promocode)
    }
  }

  return (
    <PopupPromocodeGiftBase isOpened={isOpened} onClose={onClose}>
      <Box {...headingTextProps}>
        {config.texts.popups.promocodeGift.heading.map((textLine, index) => {
          return <div key={index}>{textLine}</div>
        })}
      </Box>
      <Text {...congratulationsTextProps}>Поздравляем! Ты получил</Text>
      <Box
        w="257px"
        p="16px 8px 16px 8px"
        borderRadius="12px"
        m="0 auto 20px auto"
        backgroundColor="#ffffff"
      >
        <Text
          {...promocodeTextProps}
          dangerouslySetInnerHTML={{ __html: promocodeValue }}
        ></Text>
      </Box>
      <ActionDescriptionWrapper>
        {promocodeDescription}
      </ActionDescriptionWrapper>
      <Button {...buttonProps} onClick={onClose}>
        Продолжить
      </Button>
      <Box textAlign="center">
        <Text {...copyTextProps} onClick={onCopyPromocodeClick}>
          Копировать
        </Text>
      </Box>
    </PopupPromocodeGiftBase>
  )
}
