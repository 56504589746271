import React from 'react'

import { Flex, Text } from '../../atoms'
import { ticketsInfoWrapperPropsMobile } from './ticketsInfoWrapperPropsMobile'
import { ticketsInfoWrapperPropsDesktop } from './ticketsInfoWrapperPropsDesktop'

export const TicketsInfo = React.forwardRef(
  ({ keysCount, variant = 'mobile', ...props }, ref) => {
    let ticketsInfoWrapperProps
    if (variant === 'desktop') {
      ticketsInfoWrapperProps = ticketsInfoWrapperPropsDesktop
    } else if (variant === 'mobile') {
      ticketsInfoWrapperProps = ticketsInfoWrapperPropsDesktop
    }

    return (
      <Flex {...ticketsInfoWrapperProps} {...props} ref={ref}>
        <Text
          fontSize="16px"
          fontWeight="400"
          lineHeight="24px"
          textAlign="center"
          color="#fff"
        >
          Здесь хранятся твои билеты на еженедельные розыгрыши iPhone 15
        </Text>
      </Flex>
    )
  }
)
