import { keysInfoWrapperPropsMobileNewYearGame } from './keysInfoWrapperPropsMobileNewYearGame'
import { keysInfoWrapperPropsMobileTreasureIslandGame } from './keysInfoWrapperPropsMobileTreasureIslandGame'

const keysInfoWrapperPropsMobileByGameId = {
  1: keysInfoWrapperPropsMobileNewYearGame,
  2: keysInfoWrapperPropsMobileTreasureIslandGame,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keysInfoWrapperPropsMobile =
  keysInfoWrapperPropsMobileByGameId[gameId]
